import { theme } from '@l_ine/core';

const themeDefault = theme.createTheme({
  palette: {
    primary: {
      color: '#183781',
      contrast: '#ECECEC',
    },
    neutral: {
      color: '#ECECEC',
      contrast: '#000',
    },
  },
});

export default themeDefault;
