import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const { API_URL } = publicRuntimeConfig;
const apiUrl = String(API_URL);
const ambientealuno = '/ambientealuno';

import light from './themes/light';

const siteConfig = {
  siteName: 'SB App',
  siteIcon: '',
  logo: '/static/svgs/logo.svg',
  logoWhite: '',
  dashboard: '/dashboard',
  footerText: `© Copyright ${new Date().getFullYear()} iCFC. Todos os direitos reservados.`,
};

const themeConfig = {
  current: light,
};

const language = {
  languageId: 'portuguese',
  locale: 'pt',
  text: 'Português',
};

const langDir = 'ltr';

const apiUrls = {
  //USER
  login: apiUrl + ambientealuno + '/auth/login',
  logout: apiUrl + ambientealuno + '/auth/logout',
  verificar: apiUrl + ambientealuno + '/auth/token/verify',
  companyInfo: apiUrl + ambientealuno + '/info',
  forgotPassword: apiUrl + ambientealuno + '/auth/password/create',
  resetPassword: apiUrl + ambientealuno + '/auth/password/reset',

  //GENEREAL
  cities: apiUrl + ambientealuno + '/cidades',
  countries: apiUrl + ambientealuno + '/paises',
  zipCode: apiUrl + ambientealuno + '/cep',

  //STUDENT
  fetchStudent: apiUrl + ambientealuno + '/aluno',
  student: apiUrl + ambientealuno + '/aluno',
  fetchCards: apiUrl + ambientealuno + '/getprocessos',
  getFinancialList: apiUrl + ambientealuno + '/aluno/vendas',

  //SALES
  paymentMethod: apiUrl + ambientealuno + '/condicaopagamento',
  chengeInstallments: apiUrl + ambientealuno + '/numeroparcelas',
  insertSale: apiUrl + ambientealuno + '/prevenda',

  //AGENDA
  fetchStudentSchedule: apiUrl + ambientealuno + '/agenda/visualizar',
  printSchedule: apiUrl + ambientealuno + '/agenda/imprimir',
  insertClass: apiUrl + ambientealuno + '/agenda/agendar',
  changeStatusClass: apiUrl + ambientealuno + '/agenda/alterarsituacao',
  deleteClass: apiUrl + ambientealuno + '/agenda/alterarsituacao',
  getVeiculosInstrutores: apiUrl + ambientealuno + '/agenda/filtros',

  //FILES
  insertFile: apiUrl + ambientealuno + '/aluno/documento',
  viewFile: apiUrl + ambientealuno + '/aluno/documento',
  deleteFile: apiUrl + ambientealuno + '/aluno/documento',
  getFilesList: apiUrl + ambientealuno + '/aluno/documento',
};

export { siteConfig, themeConfig, language, langDir, apiUrls };
